import './Home.css';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Slider from './Slider';
import { TextField, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export default function Home() {

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className='container-fluid mt-3' data-aos="zoom-out-down">
                <Slider />
            </div>

            <div className='container-fluid'>

                {/* <div className='col-md-12 text-center mt-4' style={{ backgroundImage: 'url("partner-bg.jpg")', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} data-aos="fade-up"> */}
                <div className='col-md-12 text-center mt-4' data-aos="fade-up">
                    <div className='row p-4 shadow rounded'>
                        <div className='col-md-12 text-start'>
                            <h1 className='display-4 text-center'>Notification</h1>
                            <marquee onMouseOver={(e) => { try { e?.target?.stop() } catch (ex) { } }} onMouseOut={(e) => { try { e?.target?.start() } catch (ex) { } }}><strong className='text-start text-danger'>Our New Website is Lanuched</strong></marquee>
                        </div>
                    </div>
                </div>

                <div className='row my-5'>
                    {/* <div className='col-md-12 text-center mt-0' data-aos="zoom-out-down">
                            <div className='p-4'>
                            </div>
                        </div> */}

                    {/* ABOUT */}
                    <div className='col-md-12 text-center mt-4' data-aos="fade-up">
                        <div className='row p-4 shadow rounded'>
                            <div className='col-md-6'>
                                <img src='about2.jpg' style={{ width: '100%' }} />
                            </div>
                            <div className='col-md-6 text-start'>
                                <h1 className='display-5 mt-3 text-start'>Welcome to Shri Bageshwar Traders, Your Trusted Partner in Construction Excellence</h1>
                                <p>

At Shri Bageshwar Traders, we take pride in being one of the most reliable and renowned construction companies in Patna, Bihar. With a strong passion for building excellence, we strive to provide our clients with top-notch construction services that meet their unique needs and exceed their expectations.</p>
                                <Link to="/about" className='btn'><Button variant="contained">Read more</Button></Link>
                            </div>
                        </div>
                    </div>

                    
                    {/* <div className='col-md-12 text-center mt-4' data-aos="fade-down" data-aos-anchor-placement="bottom-bottom">
                        <div className='p-4 shadow rounded'>
                            <h1 className='display-5'>Our Team</h1>
                            <p>Something new is coming...</p>
                        </div>
                    </div> */}

                </div>
            </div>

            <div className='container-fluid bg-light mt-3 p-5 text-center' data-aos="fade-up">
                <h1 className='text-dark'>Enquire Now</h1>
                <TextField id="outlined-basic" label="Full Name" variant="outlined" className='w-100' color="primary" />
                <TextField id="outlined-basic" label="Mobile Number" variant="outlined" className='w-100 mt-2' color="primary" />
                <TextField id="outlined-basic" label="Email" variant="outlined" className='w-100 mt-2' color="primary" />
                <TextField id="outlined-basic" label="Address" variant="outlined" className='w-100 mt-2' color="primary" />
                <TextField id="outlined-basic" label="Message (Optional)" variant="outlined" className='w-100 mt-2' multiline minRows={4} color="primary" />
                <Button className='mt-2' variant="outlined" size="large" color='primary'>Submit</Button>
            </div>
        </>
    );
}
