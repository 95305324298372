import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { TextField, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from 'react';

export default function Franchise() {

  const [model, setModel] = useState('');

  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const branches = [
    {
      name: "Nalanda- Ekangarsarai",
      status: "started",
      address: "Patrkar Nagar, Opposite power grid, Jahanabad road, Ekangarsarai, Pincode 801301"
    },
    {
      name: "Jehanabad- Opening Soon",
      status: "opening soon",
      address: ""
    },
    {
      name: "Gaya",
      status: "opening soon",
      address: ""
    },
    {
      name: "Patna",
      status: "opening soon",
      address: ""
    },
    {
      name: "Gopalganj",
      status: "opening soon",
      address: ""
    },
    {
      name: "Uttar Pradesh - Tamkuhiraj",
      status: "opening soon",
      address: ""
    },
    {
      name: "Uttar Pradesh - Kushinagar",
      status: "opening soon",
      address: ""
    }
  ]
  return (
    <>
      <div className='container'>
        <div className='row my-5'>
          <div className='col-md-12 text-center' data-aos="zoom-out-down">
            <h1>Franchise</h1>
            <Link to="/" className="btn">Home</Link> <KeyboardDoubleArrowRightIcon /> <Link to="/franchise" className="btn">Franchise</Link>

            <div className='row p-4 shadow rounded'>
              <div className='col-md-6 text-start' data-aos="fade-left">
                <img src="franchise.jpg" className="border rounded" style={{ width: '100%' }} />
              </div>
              <div className='col-md-6 text-start' data-aos="fade-left">
                <h1 className='display-5 mt-3 text-start'>Start your own NanheMunhePaathshala preschool today!</h1>
                <h2 className='text-dark'>Enquire Now</h2>
                <TextField id="outlined-basic" label="Full Name" variant="outlined" className='w-100' color="primary" />
                <TextField id="outlined-basic" label="Mobile Number" variant="outlined" className='w-100 mt-2' color="primary" />
                <TextField id="outlined-basic" label="Email" variant="outlined" className='w-100 mt-2' color="primary" />
                <TextField id="outlined-basic" label="Address" variant="outlined" className='w-100 mt-2' color="primary" />
                <FormControl className='w-100 mt-2' fullWidth>
                  <InputLabel id="demo-simple-select-label">Franchise Model</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={model}
                    label="Franchise Model"
                    onChange={handleModelChange}
                  >
                    <MenuItem value={'Business Partener Model'}>Business Partner Model</MenuItem>
                    <MenuItem value={'Master Franchise'}>Master Franchise</MenuItem>
                    <MenuItem value={'Franchise'}>Franchise</MenuItem>
                  </Select>
                </FormControl>
                <TextField id="outlined-basic" label="Message (Optional)" variant="outlined" className='w-100 mt-2' multiline minRows={4} color="primary" />
                <Button className='mt-2' variant="outlined" size="large" color='primary'>Submit</Button>
              </div>

              <h1 className='mt-5'>Branches</h1>
              { branches.map(val=>{
                return ( <div className='col-md-12 text-start mt-2' data-aos="fade-left">
                  <div class="card" style={{ width: '100%;' }}>
                    <div className="card-body">
                      <h5 className="card-title">{val.name}</h5>
                      <h6 className="card-subtitle mb-2 text-muted">{val.status}</h6>
                      <p className="card-text">{val.address}</p>
                    </div>
                  </div>
                </div>)
              })}
              {/* <div className='col-md-12 text-start' data-aos="fade-left">
                <h1>Branches</h1>
                <div class="card" style={{ width: '100%;' }}>
                  <div className="card-body">
                    <h5 className="card-title">Nalanda- Ekangarsarai</h5>
                    <h6 className="card-subtitle mb-2 text-muted">Opened</h6>
                    <p className="card-text">Patrkar Nagar, Opposite power grid, Jahanabad road, Ekangarsarai, Pincode 801301</p>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
