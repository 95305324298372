import Button from "@mui/material/Button";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
import { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export default function Programmes() {

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="container">
        <div className="row my-5">
          <div className="col-md-12 text-center" data-aos="zoom-out-down">
            <h1>Programmes</h1>
            <p>
              <Link to="/" className="btn">Home</Link> <KeyboardDoubleArrowRightIcon /> <Link to="/course" className="btn">Programmes</Link>
            </p>

            <div className="row shadow rounded p-4">
              <div className='col-md-4 mt-4' data-aos="fade-up">
                <img src='card-1.jpg' style={{ width: '100%' }} />
              </div>
              <div className='col-md-8 text-start'>
                <h1 className='display-5 mt-3 text-start'>Play Group Program</h1>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile aria-label="lab API tabs example">
                        <Tab label="PRE-NURSERY" value="1" />
                        <Tab label="NURSERY" value="2" />
                        <Tab label="JUNIOR K.G" value="3" />
                        <Tab label="SENIOR K.G" value="4" />
                        <Tab label="DAY CARE" value="5" />
                        <Tab label="CLASS I" value="6" />
                        <Tab label="CLASS II" value="7" />
                        <Tab label="CLASS III" value="8" />

                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <strong>AGE 1.6 years to 2.5 years</strong><br />We provide for child teacher connect to make them feel comfortable, hence we maintain a smaller teacher student ratio.<br />Our approach ensures development of each child through the activities like play, rhymes, storytelling, arts & crafts, drama and social skills.
                    </TabPanel>
                    <TabPanel value="2">
                      <strong>AGE 2.5 years to 3.5 years</strong><br />
                      At this stage, children's vocabulary head developed enough to express their needs and receite stories & rhymes.<br />
                      Our approach enables a child to develop socializing, exploring, experimenting, logical thinking, physical development, language skills in a fun filled manner.
                    </TabPanel>
                    <TabPanel value="3">
                      <strong>AGE 3.5 years to 4.5 years</strong><br />
                      It is designed to help children build on their prior knowledge and experiences to form the concepts & acquire foundational skills.<br />
                      Our approach enables the development of skills, logical reasoning, through sorting, grouping, number concepts with mathematical operations. Our learning center's provide both free and guided play, thus enhancing the child's imagination & creativity.
                    </TabPanel>
                    <TabPanel value="4">
                      <strong>AGE 4.6 years to 5.5 years</strong><br />
                      NMP develops essential skills to be independent, knowledgeable, confident and to respect self and others.<br />
                      Our approach fosters fullness of children are -<br />
                      Language - by providing language rich environment.<br />
                      Science - by giving children opportunities to explore experiment & discover through hands on learning activities.<br />
                      Maths - by the concepts of pre math, numbers, counting and mathematical operations thus creating the curiosity & zeal of exploring.<br />
                      Life skills - through the life skill program grow for a confident transition to primary school.<br />
                    </TabPanel>
                    <TabPanel value="5">
                      We create homely environment to complement mother's affection. Our trained staff understands the importance of providing a safe, enriched and secured atmosphere to children for their development.<br />
                      Fun activities to keep children engaged and happy.<br />
                      Ample resting place to restore their energy.<br />
                      Regular interaction with parents to discuss the well-being of their children.<br />
                      Classroom program / After Play Group<br />
                    </TabPanel>
                    <TabPanel value="6">
                      <strong>AGE 5.5 years to 6.5 years</strong><br />
                      Our Class I curriculum focuses on establishing a strong foundation in language, numeracy, and social skills. Our experienced teachers use innovative methods to make learning fun and interactive, ensuring that our young students develop essential skills in:
                      <ul>
                        <li>Alphabet recognition and basic reading</li>
                        <li>Number sense and basic arithmetic operations</li>
                        <li>Shape and color recognition</li>
                        <li>Social skills, such as sharing, taking turns, and cooperation</li>
                        <li>Fine and gross motor skills through play-based activities</li>
                      </ul>
                    </TabPanel>
                    <TabPanel value="7">
                      <strong>AGE 6.5 years to 7.5 years</strong><br />
                      In Class II, we build upon the foundation established in Class I, gradually introducing more complex concepts and activities to challenge and engage our students. The curriculum includes:
                      <ul>
                        <li>Phonics and basic reading comprehension</li>
                        <li>Basic addition and subtraction concepts</li>
                        <li>Introducing basic shapes and patterns</li>
                        <li>Developing social skills, such as empathy and self-awareness</li>
                        <li>Enhancing fine and gross motor skills through arts, crafts, and physical activities</li>
                      </ul>
                    </TabPanel>
                    <TabPanel value="8">
                      <strong>AGE 7.5 years to 8.5 years</strong><br />
                      In Class III, our students continue to develop their skills and knowledge in a more structured and challenging environment. The curriculum includes:
                      <ul>
                        <li>Reading comprehension and basic writing skills</li>
                        <li>Basic multiplication and division concepts</li>
                        <li>Introducing basic science and environmental awareness</li>
                        <li>Developing critical thinking and problem-solving skills</li>
                        <li>Encouraging independence, self-confidence, and teamwork</li>
                      </ul>
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
