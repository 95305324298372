import { Link, Outlet } from "react-router-dom";
import Footer from "./Footer";
import './Nav.css';

function Nav() {
    return (
        <>
            <nav className="navbar navbar-expand-lg shadow" style={{backgroundColor: '#fdfdfd'}}>
                <div className="container-fluid">
                    <Link className="navbar-brand" to={"/"}>
                        <div className="row">
                            {/* <div className="col-4">
                            <img src="nmp_logo0.png" style={{
                                width: '98px',
                                height: '88px',
                                marginTop: '-10px',
                                marginBottom: '-10px',
                               }}/>
                               <img src="nmp_logo5.png" style={{
                                width: '200px',
                                height: '88px',
                                marginTop: '-10px',
                                marginBottom: '-10px',
                               }}/>    
                            </div>     */}
                            <div className="col-12">
                            <span style={{fontSize: '17px', fontWeight: '700'}}>Shri Bageshwar Traders</span>
                            <span style={{display: 'block', fontSize: '13px' }}></span>
                            </div>
                        </div>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-auto"> */}
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/about">About</Link>
                            </li>
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" to="/about" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    About
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item" to="/about">About us</Link></li>
                                    <li><Link className="dropdown-item" to="/about">Objectives</Link></li>
                                    <li><Link className="dropdown-item" to="/about">What we offer</Link></li>
                                    <li><Link className="dropdown-item" to="/about">Activities</Link></li>
                                </ul>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact">Contact</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" target="_blank" href="/app">App</a>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/programmes">Programmes</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/gallery">Gallery</Link>
                            </li> */}
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/franchise">Franchise</Link>
                            </li> */}
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Franchise
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item" to="/franchise">Why NanheMunhePaathShala</Link></li>
                                    <li><Link className="dropdown-item" to="/franchise">Apply for franchise</Link></li>
                                </ul>
                            </li> */}
                            {/* <li className="nav-item">
                                <a className="nav-link disabled">Disabled</a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
            <Outlet />
            <Footer/>
        </>
    )
}

export default Nav;