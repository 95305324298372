import './Footer.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Divider } from '@mui/material';


export default function Footer() {
    return (
        <>
            {/* <div className="container-fluid footer-bg" style={{ backgroundImage: 'url("footer_bg2.jpg")', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}> */}
            <div className="container-fluid footer-bg" style={{ backgroundColor: '#eeeeee'}}>
                <footer className="py-5 px-5 text-dark" style={{ marginTop: '100px' }}>
                    <div className="row">
                        <div className="col-12 col-md-4 mb-3" data-aos="flip-up" data-aos-delay="100" data-aos-duration="1000">
                            <h5>About</h5>
                            <p>India's best traders</p>
                            <div className="text-center d-block">
                                {/* <img src="nmp_logo_nobg.png" style={{ width: '100px' }} /> */}
                                <div>
                                    <a href="#"><FacebookIcon className='social-icon shadow' style={{ color: ' #3b5998' }} /></a>
                                    <a href="#"><TwitterIcon className='social-icon shadow' style={{ color: '#1da1f2' }} /></a>
                                    <a href="#"><InstagramIcon className='social-icon shadow' style={{ color: '#fa7e1e' }} /></a>
                                    <a href="#"><YouTubeIcon className='social-icon shadow text-danger' /></a>
                                </div>

                            </div>
                        </div>

                        {/* <div className="col-6 col-md-2 mb-3">
                        <h5>Pages</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-dark">Home</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-dark">Features</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-dark">Pricing</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-dark">FAQs</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-dark">About</a></li>
                        </ul>
                    </div> */}

                        <div className="col-6 col-md-4 mb-3" data-aos="flip-up" data-aos-delay="100" data-aos-duration="1000">
                            <h5>Contact</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2">
                                    <strong>Phone</strong>
                                    <a href="tel:+919470468070" className="nav-link p-0 text-dark">
                                        <PhoneEnabledIcon style={{ fontSize: "16px" }} />{" "}
                                        9470468070
                                    </a>
                                </li>
                                <Divider />
                                <li className="nav-item mb-2">
                                    <strong>Email</strong>
                                    <a href="mailto:info@shribageshwartraders.com" className="nav-link p-0 text-dark">
                                        <EmailIcon style={{ fontSize: "16px" }} />{" "}
                                        info@shribageshwartraders.com
                                    </a>
                                </li>
                                <Divider />
                                <li className="nav-item mb-2">
                                    <a href="#" className="nav-link p-0 text-dark">
                                        <strong>Address</strong>
                                        <a href="#" className="nav-link p-0 text-dark">
                                            <LocationOnIcon style={{ fontSize: "16px" }} />{" "}
                                            Gola Road, Patna, Bihar
                                        </a>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-4 mb-3" data-aos="flip-up" data-aos-delay="100" data-aos-duration="1000">
                            <form>
                                <h5>Subscribe to our newsletter</h5>
                                <p>Monthly digest of what's new and exciting from us.</p>
                                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                                    <label for="newsletter1" className="visually-hidden">Email address</label>
                                    <input id="newsletter1" type="text" className="form-control" placeholder="Email address" />
                                    <button className="btn btn-primary" type="button">Subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                        <p>© 2024 Shri Bageshwar Traders, All rights reserved.<br />Designed by <a href="https://coderelisher.com" style={{ textDecoration: 'none', color: '#000', fontWeight: '500' }} target='_blank'>CodeRelisher.com</a></p>
                        <ul className="list-unstyled d-flex">
                            <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"><use ></use></svg></a></li>
                            <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"><use ></use></svg></a></li>
                            <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"><use></use></svg></a></li>
                        </ul>
                    </div>
                </footer>
            </div>
        </>
    );
}
