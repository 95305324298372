import logo from './logo.svg';
import './App.css';
import Home from './components/Home';
import Contact from './components/Contact';
import Franchise from './components/Franchise';
import NotFound from './components/NotFound';
import { Route, Routes } from 'react-router-dom';
import Nav from './components/Nav';
import About from './components/About';
import Gallery from './components/Gallery';
import Programmes from './components/Programmes';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Nav />}>
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="programmes" element={<Programmes />} />
          <Route path="franchise" element={<Franchise />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
