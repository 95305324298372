import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

export default function NotFound() {
    return (
      <>
        <div className='container'>
            <div className='row my-5'>
                <div className='col-md-12 text-center' data-aos="zoom-out-down">
                    <h1>404</h1>
                    <p>Home <KeyboardDoubleArrowRightIcon/> Not Found</p>
                </div>
            </div>
        </div>
      </>
    );
  }
  