import Button from "@mui/material/Button";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";

export default function Gallery() {
  return (
    <>
      <div className="container">
        <div className="row my-5">
          <div className="col-md-12 text-center" data-aos="zoom-out-down">
            <h1>Gallery</h1>
            <p>
              <Link to="/" className="btn">Home</Link> <KeyboardDoubleArrowRightIcon /> <Link to="/gallery" className="btn">Gallery</Link>
            </p>

            <div className="row shadow rounded p-4">
              <div className="col-md-4" data-aos="fade-right">
                <img
                  src="view-3d-young-school-student.jpg"
                  className="border rounded"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-md-4" data-aos="fade-left">
                <img
                  src="view-3d-young-school-student.jpg"
                  className="border rounded"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-md-4" data-aos="fade-right">
                <img
                  src="view-3d-young-school-student.jpg"
                  className="border rounded"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
