export default function Slider() {
  return (
    <>
      <div
        id="carouselExampleControlsNoTouching"
        className="carousel slide"
        data-bs-touch="false"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active text-center" data-bs-interval="2000">
            <img
              src="slider4.jpg"
              className="d-block w-100"
              style={{ height: "80vh" }}
              alt="..."
            />
            <div className="carousel-caption d-block">
              <h5 className="slider-h1">Shri Bageshwar Traders</h5>
              <p className="slider-text">- - -</p>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <img
              src="slider3.jpg"
              className="d-block w-100"
              style={{ height: "80vh" }}
              alt="..."
            />
            <div className="carousel-caption d-block">
              <h5 className="slider-h1">Shri Bageshwar Traders</h5>
              <p className="slider-text">- - -</p>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <img
              src="slider5.jpg"
              className="d-block w-100"
              style={{ height: "80vh" }}
              alt="..."
            />
            <div className="carousel-caption d-block">
            <h5 className="slider-h1">Shri Bageshwar Traders</h5>
            <p className="slider-text">- - -</p>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControlsNoTouching"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControlsNoTouching"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}
