import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Link } from 'react-router-dom';

export default function About() {
    return (
      <>
        <div className='container'>
            <div className='row my-5'>
                <div className='col-md-12 text-center' data-aos="zoom-out-down">
                    <h1>About</h1>
                    <Link to="/" className="btn">Home</Link> <KeyboardDoubleArrowRightIcon /> <Link to="/contact" className="btn">About</Link>

                    <div className='row p-4 shadow rounded'>
                      <div className='col-md-6' ata-aos="fade-right">
                        <img src='about.jpg' className="border rounded" style={{width: '100%'}}/>
                      </div>
                      <div className='col-md-6 text-start' data-aos="fade-left">
                        <h1 className='display-5 mt-3 text-start'>About Shri Bageshwar Traders</h1>
                        <p>Welcome to Shri Bhwar Traders, Your Trusted Partner in Construction Excellence</p>

                        <p>At Shri Bageshwar Traders, we take pride in being one of the most reliable and renowned construction companies in Patna, Bihar. With a strong passion for building excellence, we strive to provide our clients with top-notch construction services that meet their unique needs and exceed their expectations.</p>

                        <p><strong>Our Mission</strong></p>

                        <p>Our mission is to be the most preferred construction company in Patna, Bihar, by delivering exceptional quality, innovative solutions, and impeccable customer service. We aim to build long-lasting relationships with our clients, partners, and stakeholders, while contributing to the growth and development of our community.</p>

                        <p><strong>Our Services</strong></p>

                        <p>At Shri Bageshwar Traders, we offer a comprehensive range of construction services, including:</p>

                        <p>Residential Construction: We build luxurious villas, apartments, and homes that reflect our clients' unique tastes and preferences.
Commercial Construction: Our of experts constructs office buildings, shopping malls, restaurants, and other commercial spaces that meet the needs of businesses and entrepreneurs.
Industrial Construction: We design and build warehouses, factories, other industrial facilities that are safe, efficient, and compliant with regulatory requirements.
Infrastructural Development: Our company takes on large-scale infrastructure projects, including roads, bridges, and public buildings, to support the growth of our community.
Renovation Interiors: We provide renovation and interior design services to transform existing spaces into modern, functional, and beautiful areas.</p>

<p><strong>Our Values</strong></p>

<p>At Shri Bageshwar Traders, we operate on a set of core values that guide our actions and decisions:</p>

<p>1 Quality: We prioritize excellence in everything we do, from design to delivery. 2. Integrity: We transparent, honest, and committed to upholding the highest standards of ethics. 3. Safety: We ensure a safe working environment for our employees, clients, and stakeholders. 4. Innovation: We encourage creativity and innovation in our work, embracing new technologies and techniques to improve our services. 5. Customer Focus: We listen to our clients and tailor our services to their unique needs and preferences.</p>

<p><strong>Why Choose Us</strong></p>

<p>When you choose Shri Bageshwar Traders for your construction needs, you can rely on:</p>

<p>Expertise: Our team of experienced professionals has a deep understanding of the construction industry and the Patna market.
Personalized Service: We take the time to understand your unique needs and preferences, tailoring our services to meet your goals.
Timely Completion: We prioritize on-time completion, ensuring minimal disruption to your daily life or business operations.
Budget-Friendly: We offer competitive pricing and flexible payment options to suit your budget.
Sustainability: We incorporate eco-friendly practices and sustainable materials into our construction processes, reducing our environmental footprint.</p>

<p><strong>Get in Touch</strong></p>

<p>If you're looking for a reliable and experienced construction company in Patna, Bihar, look no further. Contact us today to discuss your project requirements learn how we can help you achieve your construction goals.</p>


<p>Let's build something amazing together!</p>
                        <p></p>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
  }
  